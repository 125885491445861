import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";

import { useSwipe } from "../hooks/Touch";
import { useSideArrows } from "../hooks/Keyboard";
import { useSlides } from "../hooks/Slides";
import { Link } from "gatsby";

const Carousel = ({ className, items, height = 500, autoPlay = true, animate = true, thumbnails = false, indicators = true, arrows = true }) => {
  const [autoPlayOn, setAutoPlayOn] = useState(autoPlay);
  const { next, prev, goTo, index } = useSlides({ length: items.length, bufferMs: animate ? 500 : 0 });
  const handleNext = useCallback(() => {
    setAutoPlayOn(false);
    next();
  }, [next]);
  const handlePrev = useCallback(() => {
    setAutoPlayOn(false);
    prev();
  }, [prev]);
  const handleGoTo = useCallback((i) => {
    setAutoPlayOn(false);
    goTo(i);
  }, [goTo]);
  const keybaordProps = useSideArrows({ onLeft: handlePrev, onRight: handleNext });
  const touchProps = useSwipe({ onSwipeLeft: handlePrev, onSwipeRight: handleNext });
  return (
    <>
      {autoPlayOn && <AutoPlay onPlay={next} />}
      <Slides className={className} height={height} {...keybaordProps}>
        {items.map((item, i) =>
          <Slide
            key={i}
            index={i}
            currentIndex={index}
            height={height}
            animate={animate}
            count={items.length}
            image={item.image}
            overlay={item.overlay}
            to={item.to}
            {...touchProps}
          />
        )}
        {arrows && <ArrowButton direction="left" onClick={handlePrev} />}
        {arrows && <ArrowButton direction="right" onClick={handleNext} />}
        {indicators && <IndicatorButtons indicatorCount={items.length} currentIndex={index} onClick={handleGoTo} />}
      </Slides>
      {thumbnails && <Thumbnails>
        {items.map((item, i) =>
          <Thumbnail
            key={i}
            index={i}
            currentIndex={index}
            image={item.thumb}
            onClick={handleGoTo}
          />
        )}
      </Thumbnails>}
    </>
  );
};

const Slides = ({className, height, children, ...props }) => {
  return (
    <section
      role="presentation"
      aria-live="polite"
      aria-roledescription="carousel"
      className={classNames(className, "kgh-carousel relative")}
      style={{ height }}
      {...props}
    >
      {children}
    </section>
  );
};

const Slide = ({
  index,
  currentIndex,
  height,
  animate,
  count,
  image,
  overlay,
  to,
  children,
  ...props
}) => {
  const Component = to ? Link : "div";
  return (
    <Component
      to={to}
      role="tabpanel"
      aria-label={`Slide ${index + 1} of ${count}`}
      aria-hidden={currentIndex !== index}
      style={{ height }}
      className={classNames(
        "absolute inset-0 w-full opacity-0",
        {
          "transition-opacity duration-500": animate,
          "opacity-100 z-10": currentIndex === index,
        })}
      {...props}
    >
      {image}
      {overlay && <Overlay>{overlay}</Overlay>}
    </Component>
  );
};

const Thumbnails = ({children}) => {
  return (
    <div className="grid grid-cols-3 sm:grid-cols-5 gap-2 py-6">
      {children}
    </div>
  )
};

const Thumbnail = ({
  index,
  currentIndex,
  image,
  onClick
}) => {
  if (!image) return null;
  return (
    <button
      className={classNames("block", { "outline outline-gray-400": currentIndex === index })}
      onClick={() => onClick(index)}
      >
      {image}
    </button>
  );
};

const AutoPlay = ({ onPlay }) => {
  useEffect(() => {
    const id = setInterval(onPlay, 5000);
    return () => clearInterval(id);
  }, [onPlay]);
  return <></>;
}

const ArrowButton = ({ direction, onClick }) => {
  return (
    <div className={classNames("absolute top-1/2 -translate-y-1/2 z-20", { "left-0": direction === "left", "right-0": direction === "right" })}>
      <button onClick={onClick} className="bg-gray-500 text-white block px-4 py-6 md:py-6 text-lg opacity-50 hover:opacity-75 md:text-3xl">
        {direction === "left" && <>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="sr-only">Previous slide</span>
        </>}
        {direction === "right" && <>
          <FontAwesomeIcon icon={faChevronRight} />
          <span className="sr-only">Next slide</span>
        </>}
      </button>
    </div>
  );
};

const IndicatorButtons = ({ indicatorCount, currentIndex, onClick }) => {
  return (
    <>
      <div role="tablist" aria-label="Choose slide to display." className="flex absolute bottom-0 left-1/2 -translate-x-1/2 z-20">
        <div className="sm:hidden bg-gray-500 text-white block p-3 opacity-50">{currentIndex+1}/{indicatorCount}</div>
        {[...Array(indicatorCount).keys()].map((i) => <IndicatorButton onClick={() => onClick(i)} slideNumber={i} active={currentIndex === i} key={i} />)}
      </div>
    </>
  );
};

const IndicatorButton = ({ onClick, active, slideNumber }) => {
  return (
    <button
      role="tab"
      onClick={onClick}
      className={classNames("hidden sm:block bg-gray-500 text-white block p-2 opacity-50 hover:opacity-75", { "text-black": active })}
      aria-current={active}
    >
      <span className="not-sr-only"><FontAwesomeIcon icon={faCircle} /></span>
      <span className="sr-only">Go to slide {slideNumber}</span>
    </button>
  );
};

const Overlay = ({children}) => {
  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-80 p-4 bg-gray-500 text-white text-2xl md:text-5xl">
      {children}
    </div>
  );
};

export {
  Carousel,
};
