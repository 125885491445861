import React, { useCallback, useState } from "react";
import { API } from "aws-amplify";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { Layout } from "../components/Layout";
import {
  Form,
  Label,
  InputError,
  InputTextBox,
  InputRow,
  InputRowDivider,
  InputTextArea,
  SubmitError,
  SubmitSuccess,
  SubmitButton,
} from "../components/Forms";
import { PageSeo } from "../components/Seo";
import { Carousel } from "../components/Carousel";
import { Container, Section, Heading } from "../components/Page";
import { Copy } from "../components/Copy";
import { Button } from "../components/Button";

const carouselImageProps = {
  width: 600,
};

const PortraitsPage = () => {
  const { control, register, handleSubmit, formState: { errors, isDirty }, reset } = useForm();
  const [result, setResult] = useState();
  const onSubmit = useCallback((data) => {
    setResult("LOADING");
    API.post("kghillustrates", "/portraits-quote-form", { body: data })
      .then(() => {
        reset();
        setResult("SUCCESS");
      })
      .catch(() => {
        setResult("FAILED");
      });
  }, [reset]);

  return (
    <Layout>
      <PageSeo tabTitleSegments={["Portraits"]} title="Portraits" />
      <Carousel items={[
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Belle 2.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Ultrasound painting-6.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Blush Boho-11.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Digby 1.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Julie 2.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Kenzo-2.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Lexi 1.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Niko 2.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Snoop 1.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Teddy 3.jpg"
            alt=""
          />
        },
        {
          image: <StaticImage
            {...carouselImageProps}
            src="../images/portraits/Tux USA 1.jpg"
            alt=""
          />
        },
      ]} />
      <Container>
        <Section className="max-w-narrow">
          <Heading>Portraits</Heading>
          <Copy>
            <p>
              Kimberley can paint a range of different watercolour portraits.
              These include pet portraits, family portraits, house or building portraits and ultrasound paintings.
              They make great gifts and capture memories for your loved ones.
            </p>
            <ul>
              <li>Portraits are painted using watercolour on cold pressed 140lb watercolour paper.</li>
              <li>You can choose to have your portrait personalised with names and dates in modern hand calligraphy.</li>
              <li>Your painting will be signed and dated by Kimberley.</li>
              <li>It takes 4-5 weeks for Kimberley to complete your portrait.</li>
            </ul>
          </Copy>
        </Section>
        <Section className="max-w-narrow">
          <Heading id="form">Request A Portrait</Heading>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className="mb-4">
              Fill in the form below and Kimberley will get back to you via email.
            </p>
            <p className="mb-4">
              Please note Kimberley will require <b>in focus, high quality</b> photos of subjects before she starts any work.
              Once she receives your form she will request the photos via email.
            </p>
            <InputRow>
              <Label label="Name" required>
                <InputTextBox {...register("name", { required: true })} />
              </Label>
              {errors.name && <InputError>Please enter your name</InputError>}
            </InputRow>
            <InputRow>
              <Label label="Email" required>
                <InputTextBox {...register("email", { required: true })} />
              </Label>
              {errors.email && <InputError>Please enter your email</InputError>}
            </InputRow>
            <InputRow>
              <Label label="Telephone" required>
                <InputTextBox {...register("telephone", { required: true })} />
              </Label>
              {errors.telephone && <InputError>Please enter your telephone number</InputError>}
            </InputRow>
            <InputRow>
              <PortraitInputRow>
                <PortraitInputLabel label="Pets/People" />
                <Controller
                  control={control}
                  name="portraits.subjects.a5.subjects1"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A5 - 1 Subject" price="£60" discountedPrice="£48" value={value} onChange={onChange} />
                  )}
                />
                <Controller
                  control={control}
                  name="portraits.subjects.a5.subjects2"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A5 - 2 Subjects" price="£80" discountedPrice="£64" value={value} onChange={onChange} />
                  )}
                />
                <Controller
                  control={control}
                  name="portraits.subjects.a5.subjects3"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A5 - 3 Subjects" price="£100" discountedPrice="£80" value={value} onChange={onChange} />
                  )}
                />
                <InputRowDivider />
                <Controller
                  control={control}
                  name="portraits.subjects.a4.subjects1"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A4 - 1 Subject" price="£100" discountedPrice="£80" value={value} onChange={onChange} />
                  )}
                />
                <Controller
                  control={control}
                  name="portraits.subjects.a4.subjects2"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A4 - 2 Subjects" price="£120" discountedPrice="£96" value={value} onChange={onChange} />
                  )}
                />
                <Controller
                  control={control}
                  name="portraits.subjects.a4.subjects3"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A4 - 3 Subjects" price="£140" discountedPrice="£112" value={value} onChange={onChange} />
                  )}
                />
                <Controller
                  control={control}
                  name="portraits.subjects.a4.subjects4"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A4 - 4 Subjects" price="£160" discountedPrice="£128" value={value} onChange={onChange} />
                  )}
                />
                <Controller
                  control={control}
                  name="portraits.subjects.a4.subjects5"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A4 - 5 Subjects" price="£180" discountedPrice="£144" value={value} onChange={onChange} />
                  )}
                />
              </PortraitInputRow>
              <PortraitInputRow>
                <PortraitInputLabel label="Buildings" />
                <Controller
                  control={control}
                  name="portraits.building.a4"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A4" price="£120" discountedPrice="£96" value={value} onChange={onChange} />
                  )}
                />
              </PortraitInputRow>
              <PortraitInputRow>
                <PortraitInputLabel label="Ultrasound" />
                <Controller
                  control={control}
                  name="portraits.ultrasound.a5"
                  defaultValue={0}
                  render={({ field: { value, onChange } }) => (
                    <PortraitInput title="A5" price="£50" discountedPrice="£40" value={value} onChange={onChange} />
                  )}
                />
              </PortraitInputRow>
            </InputRow>
            <InputRow>
              <Label label="Description" required>
                <InputTextArea
                  placeholder="Please describe as much as you can about the portrait(s) you have selected."
                  {...register("description", { required: true })} />
              </Label>
              {errors.description && <InputError>Please enter a description</InputError>}
            </InputRow>
            {result === "FAILED" && <SubmitError>Something went wrong please try again</SubmitError>}
            {!isDirty && result === "SUCCESS" && <SubmitSuccess>Thank you for your query, Kimberley will be in touch via email</SubmitSuccess>}
            <SubmitButton loading={result === "LOADING"}>Send</SubmitButton>
          </Form>
        </Section>
      </Container>
    </Layout>
  );
};

const PortraitInput = ({ title, price, discountedPrice, value, onChange }) => {
  const onIncrementClick = useCallback(() => {
    onChange(value + 1);
  }, [value, onChange]);
  const onDecrementClick = useCallback(() => {
    onChange(value - 1);
  }, [value, onChange]);
  return (
    <div className="flex mb-1">
      <div className="flex-grow">{title}</div>
      <div className={classNames("mr-2", { "line-through": discountedPrice })}>{price}</div>
      {discountedPrice && <div className="mr-2 text-red-700">{discountedPrice}</div>}
      <div className="flex w-[85px]">
        {value === 0 && <PortraitInputButton onClick={onIncrementClick} className="flex-grow">
          Add
        </PortraitInputButton>}
        {value > 0 && <>
          <PortraitInputButton onClick={onDecrementClick}>
            <FontAwesomeIcon icon={faMinus} />
          </PortraitInputButton>
          <div className="text-sm px-2 flex-grow text-center">
            {value}
          </div>
          <PortraitInputButton onClick={onIncrementClick}>
            <FontAwesomeIcon icon={faPlus} />
          </PortraitInputButton>
        </>}
      </div>
    </div>
  );
};

const PortraitInputRow = ({ children }) => {
  return (
    <div className="mb-6">
      {children}
    </div>
  );
};

const PortraitInputLabel = ({ label, className, ...props }) => {
  return (
    <Label label={label} className="mb-2" {...props} />
  );
};

const PortraitInputButton = ({ className, children, ...props }) => {
  return (
    <Button {...props} type="button" className={classNames("text-xs px-2 py-0", className)}>
      {children}
    </Button>
  );
};

export default PortraitsPage;
