import { useState, useCallback } from "react";

const useSwipe = ({senstivity = 75, onSwipeLeft, onSwipeRight}) => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const handleTouchStart = useCallback((e) => {
    setTouchStart(e.targetTouches[0].clientX);
    setTouchEnd(e.targetTouches[0].clientX);
  }, []);
  const handleTouchMove = useCallback((e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  }, []);
  const handleTouchEnd = useCallback(() => {
    if (touchStart - touchEnd > senstivity) {
      onSwipeRight();
    }
    if (touchStart - touchEnd < -senstivity) {
      onSwipeLeft();
    }
  }, [senstivity, touchStart, touchEnd, onSwipeLeft, onSwipeRight]);
  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
  };
};

export {
  useSwipe,
};
