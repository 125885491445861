import { useCallback } from "react";

const useSideArrows = ({onLeft, onRight}) => {
  const handleKeyDown = useCallback((e) => {
    if (e.key === "ArrowRight") {
      onRight();
    }
    if (e.key === "ArrowLeft") {
      onLeft();
    }
  }, [onLeft, onRight]);

  return {
    tabIndex: -1,
    onKeyDown: handleKeyDown,
  };
};

export {
  useSideArrows,
};
