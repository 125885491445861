import { useState, useCallback } from "react";

const useSlides = ({length, bufferMs}) => {
  const [index, setIndex] = useState(0);
  const [buffering, setBuffering] = useState(false);
  const buffer = useCallback(() => {
    if (bufferMs) {
      if (buffering) return false;
      setBuffering(true);
      setTimeout(() => {
        setBuffering(false);
      }, bufferMs);
      return true;
    }
    return true;
  }, [bufferMs, buffering]);
  const next = useCallback(() => {
    if (buffer()) {
      setIndex(i => {
        if (i === length - 1) {
          return 0;
        }
        return i + 1;
      });
    }
  }, [buffer, length]);
  const prev = useCallback(() => {
    if (buffer()) {
      setIndex(i => {
        if (i === 0) {
          return length - 1;
        }
        return i - 1;
      });
    }
  }, [buffer, length]);
  const goTo = useCallback((destination) => {
    if (buffer()) {
      if (destination < 0) {
        setIndex(0);
      }
      if (destination > length - 1) {
        setIndex(length - 1);
      }
      setIndex(destination);
    }
  }, [buffer, length]);
  return {
    index,
    goTo,
    next,
    prev,
  };
};

export {
  useSlides,
};
